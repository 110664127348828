
#forcedBackGround.btn {
  background-color: #000000 !important;
  color: white !important;
  border-color: rgb(0, 0, 0) !important;
}

#forcedBackGround2 {
  background-color: #007bff !important;
  color: white !important;
  border-color: #007bff !important;
  opacity: 1;
}

.btn-primary{
  background-color: #FFD33B;
  border: 1px solid #FFD33B;
  color: #fff;
}
.btn-warning{
  color: white!important;
}
/*#import-button {*/
/*  border-color: #f06e03;*/
/*  color: #aa4c00 !important;*/
/*}*/

/*#import-button:hover {*/
/*  color: black !important;*/
/*  background-color: #f06e03;*/
/*}*/

#Docmodal {
  transform: translate(0, 0%) !important;
}

/* STYLE DATERANGE HERE  */
#dateRange{
  position:absolute;
  left: 480px;
  margin-top:50px;
  z-index: 999;
}

@media (max-width: 768px) {
  #dateRange{
    position: absolute;
    left: 50px;
    margin-top: 100px;
    z-index: 999;
  }
}

#dateRange-badge{
  position:absolute;
  left: 400px;
  margin-top:50px;
  z-index: 999;
}

@media (max-width: 768px) {
  #dateRange-badge{
    position: absolute;
    left: 50px;
    margin-top: 100px;
    z-index: 999;
  }
}

#dateRange-colis{
  position:absolute;
  left: 150px;
  margin-top:10px;
  z-index: 999;
}

@media (max-width: 768px) {
  #dateRange-colis{
    position: absolute;
    left: 50px;
    margin-top: 100px;
    z-index: 999;
  }
}

.pdfFstPage {
  width: 100% !important;
  height: 100% !important;
}

.fc-event {
  background-color: white;
}