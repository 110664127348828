@import url("https://fonts.googleapis.com/css?family=Numans");

.background {
  background-image: url("https://www.interior.fr/wp-content/uploads/2020/10/ARS_INTERIOR_BATIMENT_DE_NUIT.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-family: "Numans", sans-serif;
  width: 100%;
}

.background .container {
  height: 100%;
  align-content: center;
}

#loginPage.card {
  height: 370px;
  margin-top: 500px;
  margin-bottom: 500px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#loginPage.card .card-header {
  justify-content: center;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

#loginPage.card .card-header h3 {
  text-align: center;
  color: black;
  margin-top: -2px;
  padding-bottom: 5px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #ffc312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 150px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}
