/* GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Fjalla+One|Poppins:300,400,500,600,700,900&subset=latin-ext');


@font-face {
	font-family: "LemonMilk";
	src: url("../fonts/LEMONMILK-Medium.otf");
}
@font-face {
	font-family: "CeraPro";
	src: url("../fonts/cera-pro.otf");
}

.ceraPro {
	font-family: "CeraPro";
}

.lemonMilk {
	font-family: "LemonMilk";
}

/* BODY */


/* SECTIONS */
main{ width: 100%; float: left; position: relative; z-index: 2; }
section{position: relative;}
aside{position: relative;}
footer{position: relative;}

.appointment .solution {
	display: flex;
}

/* CONTENT */
.appointment .content{ width: calc(100% - 120px); float: left; background: black; margin: 0 60px;}




/* HEADER */
.appointment .header{ width: 100%; position: absolute; left: 0; top: -100%; z-index: 5; padding: 30px 100px; opacity: 0;}
.appointment .header{-webkit-transition: all 1s ease; -moz-transition: all 1s ease; -ms-transform:all 1s ease; transition: all 1s ease; }
.appointment .header .logo{float: left; margin-right: 20px;}
.appointment .header .logo img{ width: 50px;}
.appointment .header .phone{float: left; color: #fff !important; line-height: 32px; font-family: "Fjalla One";}
.appointment .header .language{ float: right; margin-right: 30px; margin-bottom: 0;}
.appointment .header .language li{ float: left; list-style: none; margin: 0; margin-left: 20px;}
.appointment .header .language li a{float: left; color: #fff; line-height: 35px;}
.appointment .header .language li a:hover{text-decoration: none; opacity: 0.8;}
.appointment .header .hamburger{float: right; margin-top: -5px;}
.appointment .header .equalizer{float: right; margin-top: 5px; margin-right: 20px;}
.appointment .overflow-hidden .header .logo img{-webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);}

.appointment.contact{ width: 100%; float: left; padding-top: 120px; padding-bottom: 90px;}
.appointment.contact .titles{ width: 100%; float: left; text-align: center; margin-bottom: 40px; font-family:'CeraPro';margin-top: 18px;}
.appointment.contact .titles h5{ font-size: 12px; font-weight: 700; text-transform: uppercase;}
.appointment.contact .titles h2{ font-family: "LemonMilk"; font-size: 4em; }
.appointment.contact .address{ width: 100%; float: left; text-align: center; padding: 0 10%; margin-bottom: 50px;}
.appointment.contact .address h4{ display: block; font-family: "Playfair Display"; font-size: 24px; line-height: 32px; margin-bottom: 20px; }
.appointment.contact .address p{display: block;}
.appointment.contact .address p a{font-weight: 600; color: #131314;}
.appointment.contact .address a{display: inline-block; font-weight: 600; color: #131314;}
.appointment.contact .address a:hover{text-decoration: none;}
.appointment.contact .address a svg{ width: 20px; display: inline-block; margin-bottom: -8px; }
.appointment.contact form{ margin-top: 0px;}
.appointment.contact form .form-group{margin-bottom: 15px;}
.appointment.contact form label{ width: 100%; float: left; margin: 0; position: relative; z-index: 2;}
.appointment.contact form label span{float: left; background: #fff; font-size: 12px; margin-left: 14px; padding: 0 6px; font-weight: 600; margin-bottom: -10px;}
.appointment.contact form input[type="text"]{ width: 100%; height: 40px; border: 1px solid #ccc; padding: 0 20px;}
.appointment.contact form input[type="email"]{ width: 100%; height: 40px; border: 1px solid #ccc; padding: 0 20px;}
.appointment.contact form input[type="tel"]{ width: 100%; height: 40px; border: 1px solid #ccc; padding: 0 20px;}
.appointment.contact form input[type="password"]{ width: 100%; height: 40px; border: 1px solid #ccc; padding: 0 20px;}
.appointment.contact form textarea{ width: 100%; height: 100px; float: left; line-height: 26px; border: 1px solid #ccc; padding: 20px;}
.appointment.contact form button{height: 50px; line-height: 50px; display: inline-block; border:none; padding: 0 40px; font-size: 16px; font-weight: 700; color: #fff; background: #ffdb09; overflow: hidden;}
.appointment.contact a{height: 60px; line-height: 58px; display: inline-block; border:none; padding: 0 40px; font-size: 11px; font-weight: 700; color: #fff; background: #131314; overflow: hidden;}
.appointment.contact .alert{ width:100%; float:left;}
.appointment.contact #success, .contact #error {display:none}
.appointment.contact #success, .contact #error { float:left;}
.appointment.contact #contact label.error{ width:100%; color:#f50c1a; margin-top: 10px; text-align: center;}
.appointment.contact #contact input.error  {width:100%; border:1px solid #f50c1a;}
.appointment.contact #contact textarea.error{width:100%; border:1px solid #f50c1a;}
.appointment.map{ width: 70%; height: 420px; display: inline-block; margin:0 15%;  background: #f50c1a; display: none;}




/* RESPONSIVE MEDIUM  FIXES */
@media only screen and (max-width: 1199px), only screen and (max-device-width: 1199px) {
	.appointment .header{padding: 30px 60px;}
}




/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px), only screen and (max-device-width: 991px) {
	.appointment .contact form input[type="text"]{border-radius: 0 !important; box-shadow: none !important;}
	.appointment .contact form textarea{border-radius: 0 !important; box-shadow: none !important;}
}




/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
	.appointment .header{padding: 30px;}
	.appointment .header .language{display: none;}
	.appointment .contact .titles{padding: 0;}
}


.appointment #logo {
	width: 40px;
}

.appointment div .error {
	color: red;
	font-size: 16px;
}

.appointment div .success {
	color: green;
	font-size: 16px;
}

/* image a gauche du formuliare */

.image-bg{

	background-image: url(../../assets/img/WeSmile-HAGD.png);
	width: 400px;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	height: 100px;
	margin: auto;
	text-align: center;
	margin-top: 45px;
}
